<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                   
                     <li class=" a"><router-link to='/index/zhiwei'>职位管理</router-link></li>
                       <li class="active a"> 财务总监</li>
                 
                </div>
                 <div  class="item">  
                  <li class="a" ><router-link to="/index/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-10px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">总台管理<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                      <el-dialog  class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                              <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                                <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <p style="font-size: 22px;

font-weight: 600;
color: #000000;
line-height: 30px;margin:20px 40px">财务总监</p>
            <div class="content" style="padding:20px 40px;background:#f5f5f5">
             
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">列表</p>
<div>
        <el-button  type='info'  size="mini" @click="exportOrderData()"><i class="el-icon-download"></i>导出</el-button>

            <el-button
          size="mini"
           type='warning'
           @click="dialogFormVisible3 = true">创建账号</el-button>

</div>
        
         

               <el-dialog  title="创建管理员" :visible.sync="dialogFormVisible3">
                <el-form :model="form1">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                    </el-form-item>
                     <el-form-item label="电话" :label-width="formLabelWidth">
                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                    </el-form-item>

                       <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form1.id" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                    <el-input v-model="form1.key_2" autocomplete="off"></el-input>
                    </el-form-item>

                     <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form1.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 20PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                    
         
                  <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
            
                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                </div>
                </el-dialog>
   </div>

   <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input2">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input3">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div>
   
                       <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
     id="out-table"
     height='350'
    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
      <el-table-column     
      label="用户状态" 
           :show-overflow-tooltip="true" 
     >
      <template slot-scope="scope" >
       <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
      </template>
    </el-table-column>


     <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find(scope.$index, scope.row)">查看</el-button>
                            

                                        <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind1"
                                     
                                        class="he"
                                        style="text-align:left"
                                       >
                                        <h2 style="font-size:18px;font-weight:600;margin:10px 0">省份:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find1" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                         <h2 style="font-size:18px;font-weight:600;margin:10px 0">城市:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find2" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                           <h2 style="font-size:18px;font-weight:600;margin:10px 0">地区:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find3" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                        <!-- <p>省份</p>
                                         <div class="" v-for="(item,index) in find2" :key="index">
                                            <p>{{item}}</p> 
                                        </div>
                                        <p>省份</p>
                                      <div class="" v-for="(item,index) in find3" :key="index">
                                            <p>{{item}}</p> 
                                        </div> -->

                                        </el-dialog>
                        
                    </template>
                    </el-table-column>

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
        <el-button
          size="mini"
            type="danger"
            v-if='scope.row.isactive==0'
           @click="handleEdit(scope.$index, scope.row)">禁用账号</el-button>
             <el-button
                 v-if='scope.row.isactive!==0'
          size="mini"
            type='info'
           @click="handleEdit1(scope.$index, scope.row)">恢复账号</el-button>
        
     </template>
    </el-table-column>
      <el-table-column
                    
                    label="删除账号"
                >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc6(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
  </el-table>
     <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page_index"
      :page-sizes="pagination.page_sizes"
      :page-size="pagination.page_size"
      :layout="pagination.layout"
      :total='pagination.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div>
  </div>
</template>

<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
        
    filters: {
  capitalize: function (value) {
  if(value==0){
      return '正常'
    
  }else {
      return '禁用'
  }
  }
},
 data(){
        return{
            find1:[],
              find2:[],
                find3:[],
            options:[],
               options2:[''],
            input2:'',
            input3:'',
             pagination:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
            dialogVisiblefind1:false,
            result:'',
             producImg: '',
          
               producImg1: '',
             props: { multiple: true },
            arr:'',
       
            form1: {
          name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
          
       
        },
dialogFormVisible3:false,
            password:'',
            isactive:0,
            options1:'',
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          date3:[],
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],

            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       methods:{
               sc6(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :3  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                         
                          
                                
                        })
        .catch(function(){
           
        })  
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
         ck1(){
               this.input2=''
               this.input3=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :3 }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
           },
           ck2(){
     
               var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
     
      

    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :3,
       name:this.input2,
       phone:this.input3,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
            
           },

             handleSizeChange(val) {
this.pagination.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination.page_index,
       limit :val,
       roleid :3  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange(val) {
       this.pagination.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination.page_size,
       roleid :3  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },

           find(index,row){
    this.dialogVisiblefind1 =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                              var arr1 = []
                               var arr2 = []
                                 var arr3 = []
                               for (var key in res.data.data) {
                                   arr = arr.concat(key)
                                  arr1 =arr1.concat(res.data.data[key])

                                         
                                }
                              for(var i = 0 ;i<arr1.length;i++){
                                   for (var key1 in arr1[i]) {
                                arr2 =arr2.concat(key1)
                                  arr3 =arr3.concat(arr1[i][key1])       
                                }
                              }
                           this.find1 =arr
                            this.find2 =arr2  
                          this.find3 =arr3
                            })
            .catch(function(){
            
            })  

},
             a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
              changeImage(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
     
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg = "http://gqtlgj.cn/" + res.data.key;
                                        console.log(this.producImg)
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
        changeImage1(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
       
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg1 = "http://gqtlgj.cn/" + res.data.key;
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
              changeProvinceCity(val) { 
               let arr = []
        
         
                for(var i =0;i<val.length;i++){
             arr = arr.concat(val[i][2])
                }
                   this.arr =arr
              
},
             a22(){
            this.password2=true
         
              
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }else{
                                        this.$message({
                    message:res.data.msg,
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
      exportOrderData() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '财务总监列表.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },

           tianjia(){
        
       
             if(this.form1.key_2!=='' && this.form1.phone!=='' && this.form1.name !=='' && this.arr !== '' && this.form1.worktime!=='' &&this.form1.id!==''&&this.producImg !==''&&this.producImg1!==''){
                 console.log()     
                          var d1 =     this.form1.id.substring(5,9)
                                   var d2 =  this.form1.id.substring(10,12)
                                   var d3 = d1+'-'+d2
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                             "birthday":d3,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form1.worktime,
                        "arealist": this.arr,
                        "id": this.form1.id,
                          "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "string",
                        "key_2": this.form1.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": 3,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible3 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :3  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  
        }else{
             this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
        }
           },
          handleEdit(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{

                            if(res.data.code ==0){
                                 this.$message({
                        message:'成功',
                        type: 'success'
                            });
                                  this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :3  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :3  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
                            }else{
 this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }
                                
                            
                                    })
                    .catch(function(){
                    
                    })  
          },
             handleEdit1(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                                 this.$message({
                        message:修改成功,
                        type: 'success'
                            });
                        })
                          this.$axios.get("/s/backend/api/user_list", {
                    params: { 'accessToken':localStorage.getItem('token'),
                page :1,
                limit :20,
                roleid :3 }
                    })

                .then(res=>{
                
                    if(res.data.code ==0){
                                 this.$message({
                        message:'成功',
                        type: 'success'
                            });
                                  this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :3  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :3  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
                            }else{
 this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }
                         })            
                    .catch(function(){
                        
                    })  
          }
       },
        mounted(){
 
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })
  
       
this.$axios.get("/s/backend/arealist")

     .then(res=>{
    
        console.log(res.data)
        this.options = res.data.data
 
        })
        .catch(function(){
           
        })
           
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :3  }
        })

     .then(res=>{
    
         this.tableData= res.data.data
            this.pagination.total=  res.data.count
        })
        .catch(function(){
           
        })

         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>
.red{
    color: red;
}
/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}

/deep/.he .el-dialog{
        width: 60% !important;

  height: 80%;
  overflow-y: scroll;
  
}
/deep/ .el-dialog{
        width: 30%;


  
}





.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;border-bottom: 1px solid #f5f5f5;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-130%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>